<button *ngIf="onBusinessSide()" mat-icon-button color="primary" type="button" (click)="createCommercialCustomer()"
        class="icon-button-20"
        matTooltip="Neuen Gewerbekunden manuell anlegen" [disabled]="loading">
  <mat-icon class="icon-color" svgIcon="gewerbe_plus_icon"></mat-icon>
</button>
<button *ngIf="!onBusinessSide()" mat-icon-button color="primary" type="button" (click)="createPerson()"
        class="icon-button-20"
        matTooltip="Neuen Kunden manuell anlegen" [disabled]="loading">
  <mat-icon style="color: black" fontSet="material-icons-outlined">person_add</mat-icon>
</button>
