export const COUNTRY_CODES = [
  {code: 'DE', name: 'Deutschland'},
  {code: 'AF', name: 'Afghanistan'},
  {code: 'AL', name: 'Albanien'},
  {code: 'DZ', name: 'Algerien'},
  {code: 'AS', name: 'Amerikanisch-Samoa'},
  {code: 'VI', name: 'Amerikanische Jungferninseln'},
  {code: 'AD', name: 'Andorra'},
  {code: 'AO', name: 'Angola'},
  {code: 'AI', name: 'Anguilla'},
  {code: 'AQ', name: 'Antarktika'},
  {code: 'AG', name: 'Antigua und Barbuda'},
  {code: 'AR', name: 'Argentinien'},
  {code: 'AM', name: 'Armenien'},
  {code: 'AW', name: 'Aruba'},
  {code: 'AZ', name: 'Aserbaidschan'},
  {code: 'AU', name: 'Australien'},
  {code: 'EG', name: 'Ägypten'},
  {code: 'GQ', name: 'Äquatorialguinea'},
  {code: 'ET', name: 'Äthiopien'},
  {code: 'AX', name: 'Åland'},
  {code: 'BS', name: 'Bahamas'},
  {code: 'BH', name: 'Bahrain'},
  {code: 'BD', name: 'Bangladesch'},
  {code: 'BB', name: 'Barbados'},
  {code: 'BE', name: 'Belgien'},
  {code: 'BZ', name: 'Belize'},
  {code: 'BJ', name: 'Benin'},
  {code: 'BM', name: 'Bermuda'},
  {code: 'BT', name: 'Bhutan'},
  {code: 'BO', name: 'Bolivien'},
  {code: 'BQ', name: 'Bonaire'},
  {code: 'BA', name: 'Bosnien und Herzegowina'},
  {code: 'BW', name: 'Botswana'},
  {code: 'BV', name: 'Bouvetinsel'},
  {code: 'BR', name: 'Brasilien'},
  {code: 'VG', name: 'Britische Jungferninseln'},
  {code: 'IO', name: 'Britisches Territorium im Indischen Ozean'},
  {code: 'BN', name: 'Brunei Darussalam'},
  {code: 'BG', name: 'Bulgarien'},
  {code: 'BF', name: 'Burkina Faso'},
  {code: 'BI', name: 'Burundi'},
  {code: 'CL', name: 'Chile'},
  {code: 'CN', name: 'China'},
  {code: 'CK', name: 'Cookinseln'},
  {code: 'CR', name: 'Costa Rica'},
  {code: 'CW', name: 'Curaçao'},
  {code: 'DM', name: 'Dominica'},
  {code: 'DO', name: 'Dominikanische Republik'},
  {code: 'DJ', name: 'Dschibuti'},
  {code: 'DK', name: 'Dänemark'},
  {code: 'EC', name: 'Ecuador'},
  {code: 'SV', name: 'El Salvador'},
  {code: 'CI', name: 'Elfenbeinküste'},
  {code: 'ER', name: 'Eritrea'},
  {code: 'EE', name: 'Estland'},
  {code: 'FK', name: 'Falklandinseln'},
  {code: 'FJ', name: 'Fidschi'},
  {code: 'FI', name: 'Finnland'},
  {code: 'FR', name: 'Frankreich'},
  {code: 'GF', name: 'Französisch-Guayana'},
  {code: 'PF', name: 'Französisch-Polynesien'},
  {code: 'TF', name: 'Französische Süd- und Antarktisgebiete'},
  {code: 'FO', name: 'Färöer'},
  {code: 'GA', name: 'Gabun'},
  {code: 'GM', name: 'Gambia'},
  {code: 'GE', name: 'Georgien'},
  {code: 'GH', name: 'Ghana'},
  {code: 'GI', name: 'Gibraltar'},
  {code: 'GD', name: 'Grenada'},
  {code: 'GR', name: 'Griechenland'},
  {code: 'GL', name: 'Grönland'},
  {code: 'GP', name: 'Guadeloupe'},
  {code: 'GU', name: 'Guam'},
  {code: 'GT', name: 'Guatemala'},
  {code: 'GG', name: 'Guernsey'},
  {code: 'GN', name: 'Guinea'},
  {code: 'GW', name: 'Guinea-Bissau'},
  {code: 'GY', name: 'Guyana'},
  {code: 'HT', name: 'Haiti'},
  {code: 'HM', name: 'Heard und McDonaldinseln'},
  {code: 'HN', name: 'Honduras'},
  {code: 'HK', name: 'Hongkong'},
  {code: 'IN', name: 'Indien'},
  {code: 'ID', name: 'Indonesien'},
  {code: 'IM', name: 'Insel Man'},
  {code: 'IQ', name: 'Irak'},
  {code: 'IR', name: 'Iran'},
  {code: 'IE', name: 'Irland'},
  {code: 'IS', name: 'Island'},
  {code: 'IL', name: 'Israel'},
  {code: 'IT', name: 'Italien'},
  {code: 'JM', name: 'Jamaika'},
  {code: 'JP', name: 'Japan'},
  {code: 'YE', name: 'Jemen'},
  {code: 'JE', name: 'Jersey'},
  {code: 'JO', name: 'Jordanien'},
  {code: 'KY', name: 'Kaimaninseln'},
  {code: 'KH', name: 'Kambodscha'},
  {code: 'CM', name: 'Kamerun'},
  {code: 'CA', name: 'Kanada'},
  {code: 'CV', name: 'Kap Verde'},
  {code: 'KZ', name: 'Kasachstan'},
  {code: 'QA', name: 'Katar'},
  {code: 'KE', name: 'Kenia'},
  {code: 'KG', name: 'Kirgisistan'},
  {code: 'KI', name: 'Kiribati'},
  {code: 'CC', name: 'Kokosinseln'},
  {code: 'CO', name: 'Kolumbien'},
  {code: 'KM', name: 'Komoren'},
  {code: 'CD', name: 'Kongo'},
  {code: 'XK', name: 'Kosovo'},
  {code: 'HR', name: 'Kroatien'},
  {code: 'CU', name: 'Kuba'},
  {code: 'KW', name: 'Kuwait'},
  {code: 'LA', name: 'Laos'},
  {code: 'LS', name: 'Lesotho'},
  {code: 'LV', name: 'Lettland'},
  {code: 'LB', name: 'Libanon'},
  {code: 'LR', name: 'Liberia'},
  {code: 'LY', name: 'Libyen'},
  {code: 'LI', name: 'Liechtenstein'},
  {code: 'LT', name: 'Litauen'},
  {code: 'LU', name: 'Luxemburg'},
  {code: 'MO', name: 'Macao'},
  {code: 'MG', name: 'Madagaskar'},
  {code: 'MW', name: 'Malawi'},
  {code: 'MY', name: 'Malaysia'},
  {code: 'MV', name: 'Malediven'},
  {code: 'ML', name: 'Mali'},
  {code: 'MT', name: 'Malta'},
  {code: 'MA', name: 'Marokko'},
  {code: 'MH', name: 'Marshallinseln'},
  {code: 'MQ', name: 'Martinique'},
  {code: 'MR', name: 'Mauretanien'},
  {code: 'MU', name: 'Mauritius'},
  {code: 'YT', name: 'Mayotte'},
  {code: 'MK', name: 'Mazedonien'},
  {code: 'MX', name: 'Mexiko'},
  {code: 'FM', name: 'Mikronesien'},
  {code: 'MD', name: 'Moldawien'},
  {code: 'MC', name: 'Monaco'},
  {code: 'MN', name: 'Mongolei'},
  {code: 'ME', name: 'Montenegro'},
  {code: 'MS', name: 'Montserrat'},
  {code: 'MZ', name: 'Mosambik'},
  {code: 'MM', name: 'Myanmar'},
  {code: 'NA', name: 'Namibia'},
  {code: 'NR', name: 'Nauru'},
  {code: 'NP', name: 'Nepal'},
  {code: 'NC', name: 'Neukaledonien'},
  {code: 'NZ', name: 'Neuseeland'},
  {code: 'NI', name: 'Nicaragua'},
  {code: 'NL', name: 'Niederlande'},
  {code: 'NE', name: 'Niger'},
  {code: 'NG', name: 'Nigeria'},
  {code: 'NU', name: 'Niue'},
  {code: 'KP', name: 'Nordkorea'},
  {code: 'NF', name: 'Norfolkinsel'},
  {code: 'NO', name: 'Norwegen'},
  {code: 'MP', name: 'Nördliche Marianen'},
  {code: 'OM', name: 'Oman'},
  {code: 'TL', name: 'Osttimor'},
  {code: 'AT', name: 'Österreich'},
  {code: 'PK', name: 'Pakistan'},
  {code: 'PW', name: 'Palau'},
  {code: 'PA', name: 'Panama'},
  {code: 'PG', name: 'Papua-Neuguinea'},
  {code: 'PY', name: 'Paraguay'},
  {code: 'PE', name: 'Peru'},
  {code: 'PH', name: 'Philippinen'},
  {code: 'PN', name: 'Pitcairninseln'},
  {code: 'PL', name: 'Polen'},
  {code: 'PT', name: 'Portugal'},
  {code: 'PR', name: 'Puerto Rico'},
  {code: 'CG', name: 'Republik Kongo'},
  {code: 'RW', name: 'Ruanda'},
  {code: 'RO', name: 'Rumänien'},
  {code: 'RU', name: 'Russische Föderation'},
  {code: 'RE', name: 'Réunion'},
  {code: 'BL', name: 'Saint-Barthélemy'},
  {code: 'MF', name: 'Saint-Martin'},
  {code: 'PM', name: 'Saint-Pierre und Miquelon'},
  {code: 'SB', name: 'Salomonen'},
  {code: 'ZM', name: 'Sambia'},
  {code: 'WS', name: 'Samoa'},
  {code: 'SM', name: 'San Marino'},
  {code: 'SA', name: 'Saudi-Arabien'},
  {code: 'SE', name: 'Schweden'},
  {code: 'CH', name: 'Schweiz'},
  {code: 'SN', name: 'Senegal'},
  {code: 'RS', name: 'Serbien'},
  {code: 'SC', name: 'Seychellen'},
  {code: 'SL', name: 'Sierra Leone'},
  {code: 'ZW', name: 'Simbabwe'},
  {code: 'SG', name: 'Singapur'},
  {code: 'SX', name: 'Sint Maarten'},
  {code: 'SK', name: 'Slowakei'},
  {code: 'SI', name: 'Slowenien'},
  {code: 'SO', name: 'Somalia'},
  {code: 'ES', name: 'Spanien'},
  {code: 'LK', name: 'Sri Lanka'},
  {code: 'SH', name: 'St. Helena'},
  {code: 'KN', name: 'St. Kitts und Nevis'},
  {code: 'LC', name: 'St. Lucia'},
  {code: 'VC', name: 'St. Vincent und die Grenadinen'},
  {code: 'PS', name: 'Staat Palästina'},
  {code: 'SD', name: 'Sudan'},
  {code: 'SR', name: 'Suriname'},
  {code: 'SJ', name: 'Svalbard und Jan Mayen'},
  {code: 'SZ', name: 'Swasiland'},
  {code: 'SY', name: 'Syrien, Arabische Republik'},
  {code: 'ST', name: 'São Tomé und Príncipe'},
  {code: 'ZA', name: 'Südafrika'},
  {code: 'GS', name: 'Südgeorgien und die Südlichen Sandwichinseln'},
  {code: 'KR', name: 'Südkorea'},
  {code: 'SS', name: 'Südsudan'},
  {code: 'TJ', name: 'Tadschikistan'},
  {code: 'TW', name: 'Taiwan'},
  {code: 'TZ', name: 'Tansania, Vereinigte Republik'},
  {code: 'TH', name: 'Thailand'},
  {code: 'TG', name: 'Togo'},
  {code: 'TK', name: 'Tokelau'},
  {code: 'TO', name: 'Tonga'},
  {code: 'TT', name: 'Trinidad und Tobago'},
  {code: 'TD', name: 'Tschad'},
  {code: 'CZ', name: 'Tschechische Republik'},
  {code: 'TN', name: 'Tunesien'},
  {code: 'TM', name: 'Turkmenistan'},
  {code: 'TC', name: 'Turks- und Caicosinseln'},
  {code: 'TV', name: 'Tuvalu'},
  {code: 'TR', name: 'Türkei'},
  {code: 'UG', name: 'Uganda'},
  {code: 'UA', name: 'Ukraine'},
  {code: 'HU', name: 'Ungarn'},
  {code: 'UM', name: 'United States Minor Outlying Islands'},
  {code: 'UY', name: 'Uruguay'},
  {code: 'UZ', name: 'Usbekistan'},
  {code: 'VU', name: 'Vanuatu'},
  {code: 'VA', name: 'Vatikanstadt'},
  {code: 'VE', name: 'Venezuela'},
  {code: 'AE', name: 'Vereinigte Arabische Emirate'},
  {code: 'US', name: 'Vereinigte Staaten von Amerika'},
  {code: 'GB', name: 'Vereinigtes Königreich Großbritannien und Nordirland'},
  {code: 'VN', name: 'Vietnam'},
  {code: 'WF', name: 'Wallis und Futuna'},
  {code: 'CX', name: 'Weihnachtsinsel'},
  {code: 'BY', name: 'Weißrussland'},
  {code: 'EH', name: 'Westsahara'},
  {code: 'CF', name: 'Zentralafrikanische Republik'},
  {code: 'CY', name: 'Zypern'}
];
