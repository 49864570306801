<a mat-flat-button
   class="flex w-full label-font-weight button-height align-content-center justify-content-center overflow-hidden text-overflow-ellipsis"
   rel="opener"
   (click)="handleClick($event)"
   [color]="color || 'primary'"
   [href]="href"
   [ngClass]="!disabled && !loading ? class : null"
   [target]="target || '_self'"
   [disabled]="disabled || loading">
  <ng-content></ng-content>
  <mat-progress-spinner *ngIf="loading"
                        class="absolute spinner-position left-50 top-50"
                        mode="indeterminate"
                        color="accent"
                        [diameter]="24"
                        [strokeWidth]="2"></mat-progress-spinner>
</a>
<div *ngIf="hint" class="text-xs py-1 text-center">{{hint}}</div>
