import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

export interface EchseState {
  status: string;
}

@Injectable({
  providedIn: 'root'
})
export class EchseService {

  constructor(private http: HttpClient) {
  }

  fetchExternalContractsState(personManagementId: string): Observable<EchseState> {
    return this.http.get<EchseState>(`/echse/customers/${personManagementId}/status`);
  }
}
