import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {CrmService} from "../../services/crm.service";

@Injectable()
export class UniqueEmployerValidator {

  constructor(private crmService: CrmService) {
  }

  checkIfDuplicateExists(employerName?: string): Observable<{ uniqueEmployer: number } | null> {
    if (!employerName) {
      return of(null);
    }
    return this.crmService
      .getBusinessByName(employerName)
      .pipe(
        map(company => ({uniqueEmployer: company!.id})),
        catchError(() => of(null))
      );
  }

}
