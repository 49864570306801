import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-duplicate-tab-dialog',
  templateUrl: './duplicate-tab-dialog.component.html',
  styleUrls: ['./duplicate-tab-dialog.component.scss']
})
export class DuplicateTabDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { link: string }) {
  }

  openLink() {
    window.open(this.data.link, '_blank')
  }
}
