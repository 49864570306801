<div class="flex gap-2 align-items-center pl-3">
  <h2 mat-dialog-title class="leading-normal mb-0 pl-0">Hinweis</h2>
</div>
<mat-dialog-content class="flex">
  <span>Die Kundendaten werden aktuell von {{ data.lastAccessByName }}
    bearbeitet. Der Aufruf ist daher nicht möglich.</span>
</mat-dialog-content>
<mat-dialog-actions title="" align="end">
  <button mat-flat-button [mat-dialog-close]="true">Ok</button>
</mat-dialog-actions>
