import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title?: string, message: string, confirmText: string, hideCancel?: boolean }) {
  }


}
