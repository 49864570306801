import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';


export interface ConfirmCardDialogData {
  title: string
  message: string
  cancel: string
  confirm: string
  closable: boolean
}

@Component({
  selector: 'app-confirm-card-dialog',
  templateUrl: './confirm-card-dialog.component.html',
  styleUrls: ['./confirm-card-dialog.component.scss']
})
export class ConfirmCardDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmCardDialogData) { }
}
