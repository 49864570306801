<app-konzept-subheader></app-konzept-subheader>
<main class="search-businesses-width mx-auto justify-self-center px-3">
  <mat-card>
    <mat-card-header>
      <mat-card-title class="mb-2">Firma auswählen</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form (submit)="onSubmit()" novalidate>
        <div class="flex gap-3">
          <mat-form-field class="flex-grow w-full">
            <mat-label>Firmenname</mat-label>
            <input matInput type="text" name="name" placeholder="Name" [formControl]="name" #inputFocusElement required>
            <mat-error *ngIf="name.hasError('required')">Name muss angegeben werden</mat-error>
            <mat-error *ngIf="name.hasError('noResults')">Die gesuchte Firma konnte nicht gefunden werden</mat-error>
            <mat-error *ngIf="name.hasError('minlength')">Bitte mindestens zwei Zeichen angeben</mat-error>
          </mat-form-field>
          <button mat-mini-fab color="warn" type="submit" [disabled]="!name.valid"
                  [ngClass]="{'bg-button-yellow': name.valid}" class="mt-1 text-color">
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </form>

      <mat-nav-list>
        <mat-list-item *ngFor="let customer of customers | async" (click)="onSelect(customer)">
          <span class="flex-grow">{{ [customer.vorname, customer.nachname].join(' ').trim() }}</span>
        </mat-list-item>
      </mat-nav-list>
    </mat-card-content>
  </mat-card>
</main>
