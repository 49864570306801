import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

export interface KonzeptPageLink {
  title: string;
  visible?: boolean;
  href: string;
  target?: string;
  loading?: boolean;
  disabled?: boolean;
  color: ThemePalette;
  class?: string;
  onClick: ($event: MouseEvent) => void,
  hint?: string
}

@Component({
  selector: 'app-konzept-area',
  templateUrl: './konzept-area.component.html',
  styleUrls: ['./konzept-area.component.scss']
})
export class KonzeptAreaComponent {
  @Output()
  linkClicked: EventEmitter<KonzeptPageLink> = new EventEmitter();

  @Input()
  title: string;

  @Input()
  errorMessage: string;

  @Input()
  links: KonzeptPageLink[] = [];

  constructor () {
  }

  handleLinkClicked ($event: MouseEvent, link: KonzeptPageLink) {
    this.linkClicked.emit(link)
    if (link.onClick) link.onClick($event)
  }
}
