import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-konzept-button',
  templateUrl: './konzept-button.component.html',
  styleUrls: ['./konzept-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KonzeptButtonComponent {
  @Output()
  clicked: EventEmitter<MouseEvent> = new EventEmitter();
  @Input()
  disabled: boolean;
  @Input()
  loading: boolean;
  @Input()
  href?: string;
  @Input()
  target?: string;
  @Input()
  class?: string;
  @Input()
  hint?: string;
  @Input()
  color: ThemePalette = 'primary';

  constructor() {
  }

  handleClick ($event: MouseEvent): void {
    if (this.disabled) return
    this.clicked.emit($event);
  }
}
