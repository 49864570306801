<mat-dialog-content class="flex flex-column gap-3 pt-4">
  <span>Kundendaten übertragen im Auftrag von:</span>
  <mat-radio-group [formControl]="user" class="flex flex-column dense-2 gap-1 pl-2">
    <mat-radio-button value="{{currentUser.personManagementId}}">{{displayName(currentUser)}}</mat-radio-button>
    <mat-radio-button *ngFor="let consultant of consultantList" [value]="consultant.personManagementId">{{displayPerMaName(consultant)}}</mat-radio-button>
  </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button type="button" mat-dialog-close>Abbrechen</button>
  <button mat-flat-button type="submit" color="primary" [disabled]="!user.valid" (click)="onSubmit()">Weiter</button>
</mat-dialog-actions>
