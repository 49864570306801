<div class="flex align-items-center">
  <h2 mat-dialog-title class="delete-error-title flex-auto mb-0" *ngIf="data?.title">{{ data?.title }}</h2>
  <button *ngIf="data.closable" mat-icon-button class="icon-button-21" mat-dialog-close [autofocus]="false">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content [innerHTML]="data.message">
  <span class="whitespace-pre-wrap">{{data?.message || 'Möchtest du wirklich fortfahren?'}}</span>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button [mat-dialog-close]="false" *ngIf="data?.cancel">{{data?.cancel}}</button>
  <button mat-flat-button [mat-dialog-close]="true" *ngIf="data?.confirm">{{data?.confirm}}</button>
</mat-dialog-actions>
