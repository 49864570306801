<div class="flex justify-content-between padding-close-button-icon">
  <p mat-dialog-title class="font-normal dialog-title-size mb-0 pt-5 pl-5 pb-0">Person anlegen</p>
  <button mat-icon-button mat-dialog-close class="icon-button-20 mt-1" [autofocus]="false">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content class="pl-5 pr-5 pt-3 pb-3">
  <p class="mb-2 dialog-subtitle-size"><b>Natürliche Person anlegen</b></p>
  <p class="mb-3 mt-0">Die Anlage von natürlichen Personen erfolgt über den Potential Planer.</p>
  <div class="text-center mb-3">
    <a mat-raised-button href="{{potentialPlanerUrl}}" class="w-4 font-medium">Zum Potential Planer</a>
  </div>
  <p class="mb-2 dialog-subtitle-size"><b>Juristische Person anlegen</b></p>
  <p class="mb-3 mt-0">Zur Anlage einer juristischen Person steht die Gewerbeplattform zur Verfügung.</p>
  <div class="text-center">
    <button mat-raised-button (click)="toBusinesses()" mat-dialog-close class="w-4 font-medium">Zur Gewerbeplattform</button>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="pt-2 pr-3">
  <button mat-flat-button color="primary" mat-dialog-close class="font-medium">OK</button>
</mat-dialog-actions>
