import {ChangeDetectionStrategy, Component, Inject, OnDestroy} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {User} from '@taures/angular-commons';
import {PerMaPerson} from '../../services/person-management.service';


export interface AssistanceResult {
  self: boolean;
  superior?: string;
  email?: string;
}

@Component({
  selector: 'app-consultant-of-dialog',
  templateUrl: 'consultant-of-dialog.component.html',
  styleUrls: ['./consultant-of-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsultantOfDialogComponent implements OnDestroy {
  user = new UntypedFormControl('', [Validators.required]);
  private destroy = new Subject<void>();
  currentUser: User;
  consultantList: PerMaPerson[];

  constructor(@Inject(MAT_DIALOG_DATA) private readonly data: { currentUser: User, consultants: PerMaPerson[] },
              private dialogRef: MatDialogRef<ConsultantOfDialogComponent>) {
    this.user.setValue(data.currentUser.personManagementId);
    this.currentUser = data.currentUser;
    this.consultantList = data.consultants;
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  onSubmit(): void {
    if (this.user.valid) {
      const email = this.consultantList.find(c => c.personManagementId === this.user.value)?.emailAddress
      this.dialogRef.close(this.user.value === this.currentUser.personManagementId ?
        {self: true} as AssistanceResult : {self: false, superior: this.user.value, email } as AssistanceResult);
    }
  }

  displayName(user: User): string {
    return user.vorname + ' ' + user.nachname;
  }

  displayPerMaName(person: PerMaPerson): string {
    return person.firstName + ' ' + person.lastName;
  }
}

