import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import * as QS from 'qs'

export interface ActiveSoftfairProducts {
  locked: boolean
  products: string[]
}

export interface CustomerLink {
  success: boolean
  url?: string
  message?: string
  errorStatus?: number
  lockedBy?: {
    firstName: string
    lastName: string
    email: string
  }
  bypass?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class SoftfairService {

  constructor(private http: HttpClient) {
  }

  getCustomerLink(productKey: string, personManagementId: string, onBehalfOfId: string, force: boolean = false): Observable<CustomerLink> {
    const query = QS.stringify({ 'on-behalf-of': onBehalfOfId, force }, { skipNulls: true })
    const link = `sf-push/api/customers/${personManagementId}/${productKey}?${query}`;
    return this.http.post<CustomerLink>(link, null)
  }

  getActiveSoftfairProducts(permaId: string): Observable<ActiveSoftfairProducts> {
    return this.http.get<ActiveSoftfairProducts>(`/sf-push/api/customers/${permaId}/status`);
  }
}
