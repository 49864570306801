<div class="error-message p-3">
  <div class="flex align-items-center">
    <div class="flex-shrink-0 error-icon-container">
      <mat-icon class="material-icons">error_outline</mat-icon>
    </div>
    <div class="error-message-content">
      <p class="mb-0 mt-0">{{ errorMessage }}</p>
    </div>
  </div>
</div>
