import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { COUNTRY_CODES } from "./country-codes";

export interface City {
    name: string
}

@Injectable()
export class PlzService{
    constructor(private http: HttpClient) { }
    
    getCities(plz: string, country: string): Observable<City[]> {
        let params = new HttpParams();
        params = params.set('postal-code', plz);
        if (!!country && !!COUNTRY_CODES.find(code => country === code.code)) {
            params = params.set('country', country);
        }        
        return this.http.get<City[]>(`/dde/api/cities`, {params});
    }
}