import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Person, UserService} from '@taures/angular-commons';
import {combineLatest, Observable, of} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {AssistanceResult, ConsultantOfDialogComponent} from '../consultant-of-dialog/consultant-of-dialog.component';
import {PersonManagementService} from '../../services/person-management.service';
import {MatDialog} from '@angular/material/dialog';
import log from 'loglevel';
import {
  ConfirmCardDialogComponent,
  ConfirmCardDialogData
} from '../confirm-card-dialog/confirm-card-dialog.component';
import {SoftfairPushService} from "../../services/softfair-push.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ProblemDetail} from "../../interceptors/problem-detail-interceptor";

interface Benutzer {
  vorname: string,
  nachname: string,
  email: string
}

interface UebertragungStatus {
  success: boolean
  ssoUrl?: string,
  gesperrt: boolean,
  uebertragungErzwingbar?: boolean,
  gesperrtDurch?: Benutzer,
  fehlermeldung?: string
}

@Component({
  selector: 'app-softfair-push-dispatch',
  templateUrl: './softfair-push-dispatch.component.html',
  styleUrls: ['./softfair-push-dispatch.component.scss'],
  host: {class: 'flex flex-column flex-grow-1'}
})
export class SoftfairPushDispatchComponent implements OnInit {
  loading: boolean = true
  customer: Person;
  assistanceResult: AssistanceResult;
  message: string;

  constructor(private activatedRoute: ActivatedRoute,
              private softfairPushService: SoftfairPushService,
              private userService: UserService,
              private personManagementService: PersonManagementService,
              private changeDetector: ChangeDetectorRef,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.load();
  }

  private load(uebertragungErzwingen: boolean = false) {
    this.message = 'Daten werden übertragen'
    combineLatest([
      this.activatedRoute.params,
      this.activatedRoute.data,
      this.checkIfCurrentIsAssistant()
    ]).pipe(switchMap(([{productKey}, {customer}, assistance]) => {
      if (!assistance) {
        this.closeWindow()
      }
      Object.assign(this, {loading: true, productKey, customer})
      this.changeDetector.markForCheck();
      const imAuftragVonId = assistance.self ? null : assistance.superior;
      return this.softfairPushService.uebertrageKunde(productKey, this.customer.personManagementId, uebertragungErzwingen, imAuftragVonId)
        .pipe(map(uebertragungErgebnisDto => ({
          success: true,
          ssoUrl: uebertragungErgebnisDto.ssoUrl
        }) as UebertragungStatus)
    )
    }), catchError((error: HttpErrorResponse) => {
      this.message = null
      log.warn('Get customerLink failed', error);
      if (!(error.error instanceof ProblemDetail)) {
        return of({
          success: false,
          gesperrt: false,
          fehlermeldung: error.message
        })
      }
      const problemDetail = error.error;
      return of({
        success: false,
        gesperrt: 423 === problemDetail.status,
        fehlermeldung: problemDetail.detail,
        ...problemDetail.extensions
      });

    })).subscribe((uebertragungStatus: UebertragungStatus) => {
      log.debug('customerLink fetched', uebertragungStatus)
      if (!uebertragungStatus.success && uebertragungStatus.gesperrt) {
        return this.handleHaushaltLock(uebertragungStatus)
      }
      if (!uebertragungStatus.success) {
        Object.assign(this, {loading: false, message: null})
        this.changeDetector.markForCheck();
        this.dialog.open(ConfirmCardDialogComponent, {
          panelClass: ['error-dialog'],
          width: '360px',
          data: {
            title: 'Fehler',
            message: uebertragungStatus.fehlermeldung,
            confirm: 'OK'
          } as ConfirmCardDialogData
        }).afterClosed().subscribe(() => {
          this.closeWindow()
        })
        return;
      }
      this.changeDetector.markForCheck();
      Object.assign(this, {loading: true, message: 'Du wirst nun weitergeleitet'})
      this.changeDetector.markForCheck();
      window.location.replace(uebertragungStatus.ssoUrl);
    })
  }

  checkIfCurrentIsAssistant(): Observable<AssistanceResult> {
    if (this.assistanceResult) return of(this.assistanceResult)
    return this.userService.getCurrentUser().pipe(
      switchMap(user => this.personManagementService.isAssistantOf(user.personManagementId).pipe(
        map(superiors => ({user, superiors}))
      )),
      switchMap(({user: currentUser, superiors: consultants}) => {
        this.loading = false;
        this.changeDetector.markForCheck()
        if (consultants && consultants.length) {
          return this.dialog.open(ConsultantOfDialogComponent, {
            data: {currentUser, consultants},
            panelClass: 'taures-base-dialog',
            width: '336px',
            maxHeight: '80vh'
          }).afterClosed() as Observable<AssistanceResult>;
        }
        return of({self: true} as AssistanceResult);
      }),
      tap(ar => (this.assistanceResult = ar))
    );
  }

  private handleHaushaltLock(uebertragungStatus: UebertragungStatus) {
    this.dialog.open(ConfirmCardDialogComponent, {
      panelClass: ['notification-dialog'],
      width: '340px',
      data: uebertragungStatus.uebertragungErzwingbar ? {
        title: 'Achtung',
        message: '<div class="mb-3">Du bearbeitest den Kunden möglicherweise bereits in einem anderen Fenster!</div>Um Datenverluste zu vermeiden, nutze bitte weiterhin das geöffnete Fenster oder melde dich dort aus dem KonzeptButler ab.',
        cancel: 'Trotzdem fortfahren',
        confirm: 'OK'
      } : {
        title: 'Hinweis',
        message: `Zurzeit wird die Kundin/der Kunde von ${uebertragungStatus.gesperrtDurch?.vorname} ${uebertragungStatus.gesperrtDurch?.nachname} (${uebertragungStatus.gesperrtDurch?.email}) im KonzeptButler bearbeitet. Der Aufruf ist daher nicht möglich.`,
        cancel: null,
        confirm: 'OK'
      } as ConfirmCardDialogData
    }).afterClosed().subscribe(confirmed => {
      if (uebertragungStatus.uebertragungErzwingbar && !confirmed) {
        this.load(true)
      } else {
        this.closeWindow()
      }
    });
  }

  closeWindow() {
    if (window.opener != null) window.close()
    window.location.href = (this.customer?.anrede === 'Firma' ? '/businesses/' : '/customers/') + this.customer?.id
  }
}
