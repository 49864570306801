import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';

export interface StatusDto {
  gesperrt: boolean
  zugriffAntragsverwaltung: boolean
}

export interface UebertragungErgebnisDto {
  ssoUrl: string
}

@Injectable({
  providedIn: 'root'
})
export class SoftfairPushService {
  constructor(private http: HttpClient) {
  }

  getStatus(kundeId: string): Observable<StatusDto> {
    return this.http.get<StatusDto>(`/softfair-push/api/uebertragung/${kundeId}/status`);
  }

  uebertrageKunde(produkt: string, kundeId: string, uebertragungErzwingen: boolean,
                  imAuftragVonId?: string): Observable<UebertragungErgebnisDto> {
    let params = new HttpParams()
      .set('uebertragung-erzwingen', uebertragungErzwingen);
    if (imAuftragVonId) {
      params = params.set('im-auftrag-von-id', imAuftragVonId)
    }
    return this.http.post<UebertragungErgebnisDto>(`softfair-push/api/uebertragung/${kundeId}/${produkt}`, null, {params})
  }
}
