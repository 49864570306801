import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {cache} from "@taures/angular-commons";

export interface CrmCustomer {
  crmId: number,
  completed: boolean,
  consultant: {
    chamberOfCommerceMissing: boolean
  },
  firstInformationReceived: Date,
  firstInformationCreatedBy: string
}

@Injectable()
export class ErstinformationService {

  constructor(private http: HttpClient) {
  }

  loadCustomer(permaId: string): Observable<CrmCustomer> {
    return this.http.get<CrmCustomer>(`/erstinformation/api/customers/${permaId}`).pipe(
      cache('crmCustomer' + permaId, 2000)
    )
  }
}
