import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

export interface CustomerStatus {
  lastAccessByName?: string | null;
  started: boolean;
  active: boolean;
  completed: boolean;
}

@Injectable()
export class DdeService {

  constructor(private http: HttpClient) {
  }

  getCustomerStatus(permaId: string): Observable<CustomerStatus> {
    return this.http.get<CustomerStatus>(`/dde/api/customers/${permaId}/status`);
  }

}
