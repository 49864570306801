import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {BusinessSearchComponent} from './components/business-search/business-search.component';
import {CustomerSearchComponent} from './components/customer-search/customer-search.component';
import {PersonResolver} from './services/person-resolver.service';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {SoftfairDispatchComponent} from './components/softfair-dispatch/softfair-dispatch.component';
import {KonzeptPageComponent} from './components/konzept-page/konzept-page.component';
import {SoftfairPushDispatchComponent} from "./components/softfair-push-dispatch/softfair-push-dispatch.component";

const appRoutes: Routes = [
  { path: '', redirectTo: 'customers', pathMatch: 'full' },

  { path: 'customers', component: CustomerSearchComponent },
  { path: 'customers/:personManagementId', component: KonzeptPageComponent, resolve: {customer: PersonResolver} },
  { path: 'customers/:personManagementId/dispatch/softfair/:productKey', component: SoftfairDispatchComponent, resolve: {customer: PersonResolver} },
  { path: 'customers/:personManagementId/dispatch/softfair-push/:productKey', component: SoftfairPushDispatchComponent, resolve: {customer: PersonResolver} },

  { path: 'businesses', component: BusinessSearchComponent },
  { path: 'businesses/:personManagementId', component: KonzeptPageComponent, resolve: {customer: PersonResolver} },
  { path: 'businesses/:personManagementId/dispatch/softfair/:productKey', component: SoftfairDispatchComponent, resolve: {customer: PersonResolver} },
  { path: 'businesses/:personManagementId/dispatch/softfair-push/:productKey', component: SoftfairPushDispatchComponent, resolve: {customer: PersonResolver} },

  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {})
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
