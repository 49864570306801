<app-konzept-subheader [customer]="customer | async">
</app-konzept-subheader>

<main class="px-3 mx-auto card-width">
  <div class="flex gap-3 flex-column">
      <app-konzept-area *ngFor="let area of areas" [title]="area.title" [links]="area.links"
                        [errorMessage]="area.errorMessage"></app-konzept-area>
      <a #hiddenLink style="display: none" target="_blank"></a>
    </div>
</main>
