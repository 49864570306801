import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import {KeycloakTokenService} from '@taures/angular-commons';
import { ActivationEnd, Router } from '@angular/router';
import {
  CUSTOMER_LINK_KEY_ANTRAG,
  CUSTOMER_LINK_KEY_FL,
  CUSTOMER_LINK_KEY_LV,
  CUSTOMER_LINK_KEY_PKV
} from './components/konzept-page/konzept-page.component';

const defaultTitle = "Konzepterstellung";
const titleByProductKey = {
  [CUSTOMER_LINK_KEY_FL]: 'KonzeptButler',
  [CUSTOMER_LINK_KEY_LV]: 'LV-Modul',
  [CUSTOMER_LINK_KEY_PKV]: 'PKV-Modul',
  [CUSTOMER_LINK_KEY_ANTRAG]: 'Antragsverwaltung'
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  username = this.token.name;
  title = null;
  constructor(private token: KeycloakTokenService, private router: Router, private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
    this.router.events.subscribe(data => {
      if (data instanceof ActivationEnd) {
        const productKey = data.snapshot?.params?.productKey
        this.title = productKey ? titleByProductKey[productKey] || defaultTitle : defaultTitle
        this.changeDetector.markForCheck()
      }
    })
  }

}
