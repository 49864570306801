<mat-card>
  <mat-card-header>
    <mat-card-title class="mb-2">{{ title }}</mat-card-title>
    <div class="grow"></div>
    <mat-error *ngIf="errorMessage" class="loading-error text-sm">{{ errorMessage }}</mat-error>
  </mat-card-header>
  <mat-card-content class="card-padding">
    <div class="grid">
      <app-konzept-button class="col-12 sm:col-6 md:col-4" *ngFor="let link of links"
                          (clicked)="handleLinkClicked($event, link)"
                          [color]="link.color"
                          [class]="link.class"
                          [href]="link.href"
                          [target]="link.target"
                          [disabled]="link.disabled"
                          [hint]="link.hint"
                          [loading]="link.loading">{{ link.title }}
      </app-konzept-button>
    </div>
  </mat-card-content>
</mat-card>
