import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import {Router} from "@angular/router";

@Component({
  selector: 'app-create-customer-redirect-dialog',
  templateUrl: './create-customer-redirect-dialog.component.html',
  styleUrls: ['./create-customer-redirect-dialog.component.scss']
})
export class CreateCustomerRedirectDialogComponent {
  potentialPlanerUrl: string = environment.potentialPlanerUrl

  constructor(readonly router: Router) { }

  toBusinesses() {
    this.router.navigate(['businesses']);
  }
}
