import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {SnackbarService} from "@taures/angular-commons";

@Injectable()
export class BrunnenService {
  constructor(private http: HttpClient, private snackbarService: SnackbarService) {
  }

  downloadBrunnen(id: number): Observable<File> {
    return this.http.get(`/brunnen/api/brunnen/${id}`, {
      observe: 'response',
      responseType: 'blob'
    })
      .pipe(
        map((response: HttpResponse<Blob>) => {
          const contentType = response.headers.get('Content-Type');
          let fileName = 'file';

          const match = response.headers.get('Content-Disposition').match(/filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/);
          if (match && match.length > 1) {
            fileName = match[1];
          }
          return new File([response.body], decodeURI(fileName), {type: contentType});
        }),
        catchError((error: any) => {
          this.snackbarService.queueToastMessage({
            title: 'Fehler',
            message: 'Dokument konnte nicht generiert werden.',
            duration: 5000,
            notificationType: "error"
          });
          console.error('An error occurred', error);
          return of(null);
        }));
  }
}
