import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

export interface MscStatus {
  status: UebertragungStatus,
  message?: string | undefined
}

export enum UebertragungStatus {
  UEBERTRAGBAR = 'UEBERTRAGBAR',
  NICHT_UEBERTRAGBAR = 'NICHT_UEBERTRAGBAR'
}

@Injectable()
export class MscPushService {

  constructor(private http: HttpClient) {
  }

  getStatus(customerId: string): Observable<MscStatus> {
    return this.http.get<MscStatus>(`/msc-push/api/uebertragung/${customerId}/status`);
  }

  uebertrageKunde(customerId: string): Observable<void> {
    return this.http.post<void>('/msc-push/api/uebertragung/' + customerId, null);
  }
}
