<div class="flex align-items-center">
  <h2 mat-dialog-title class="flex-auto mb-0">Hinweis</h2>
  <button mat-icon-button class="icon-button-21 mr-2" [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <p>Die Erstinformation wurde bereits
    am {{ data.received | date: 'dd.MM.yyyy' }}{{ data.createdBy ? ' durch ' + data.createdBy : '' }} erstellt.</p>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button [mat-dialog-close]="false">Ok</button>
  <button mat-flat-button [mat-dialog-close]="true">Erneut versenden</button>
</div>
