import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-konzept-footer',
  templateUrl: './konzept-footer.component.html',
  styleUrls: ['./konzept-footer.component.scss'],
  host: { class: 'mt-3 px-2 align-items-center flex', style: 'background-color: #003572; height: 2.5rem' }
})
export class KonzeptFooterComponent  {
  constructor() { }
}
