export const environment = {
  production: true,
  oauth: {
    issuer: 'ISSUER_URL',
    clientId: 'CLIENT_ID',
    scope: 'openid email',
    responseType: 'code',
    disablePKCE: true // this is not recommended, but otherwise, the keycloak.js adapter would not work
  },
  retirementCalculatorUrl: 'RETIREMENT_CALCULATOR_URL',
  echseUrl: 'ECHSE_URL',
  firstInformationUrl: 'FIRST_INFORMATION_URL',
  ddeUrl: 'DDE_URL',
  potentialPlanerUrl: 'POTENTIALPLANER_URL',
  docsUrl: 'DOCS_URL',
  rvaUrl: 'RVA_URL',
  crmUrl: 'CRM_URL'
};
