<div class="flex align-items-center">
  <h2 mat-dialog-title class="flex-auto mb-0">Hinweis</h2>
  <button mat-icon-button class="icon-button-21 mr-2" [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content class="flex">
    <span>Die Datenerfassung ist möglicherweise bereits in einem anderen Browser oder
      Tab geöffnet!</span>
</div>
<mat-dialog-actions title="" align="end">
  <button mat-stroked-button mat-dialog-close (click)="openLink()">Trotzdem fortfahren</button>
  <button mat-flat-button mat-dialog-close>OK</button>
</mat-dialog-actions>
